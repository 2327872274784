import Vue from 'vue'
import { parseISO, format, formatDistance } from 'date-fns'

Vue.filter('timeago', value => {
  if (!value) return '---'

  const timeStamp = parseISO(value)
  if (timeStamp.toString() === 'Invalid Date') return timeStamp.toString()
  return formatDistance(timeStamp, new Date(), { addSuffix: true} )
})

Vue.filter('formattedTimestamp', value => {
  if (!value) return '---'

  const timeStamp = parseISO(value)
  if (timeStamp.toString() === 'Invalid Date') return timeStamp.toString()
  return format(timeStamp, 'yyyy-MM-dd HH:mm:ss OOO')
})

Vue.filter('formattedDatetime', value => {
  if (!value) return '---'

  const timeStamp = parseISO(value)
  if (timeStamp.toString() === 'Invalid Date') return timeStamp.toString()
  return format(timeStamp, 'iii MM/dd/yyyy hh:mm a')
})

Vue.filter('humanReadableBytes', value => {
  if (!value) return '---'

  const i = Math.floor(Math.log(value) / Math.log(1024))
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  return (value / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + sizes[i]
})
