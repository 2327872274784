// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
require.context('../images', true)
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../styles/application.scss'

import Vue from 'vue'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import ActionCableVue from 'actioncable-vue'
Vue.use(ActionCableVue, {
  debug: process.env.NODE_ENV === 'development',
  debugLevel: process.env.NODE_ENV === 'development' ? 'all' : 'error',
  connectImmediately: false,
})

import axios from 'axios'
// Tell axios to send the CSRF token (from cookie) in the header
// named 'X-CSRF-TOKEN' which is expected by Rails
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

import { InertiaProgress } from '@inertiajs/progress'
InertiaProgress.init({
  delay: 250,
  color: '#29d',
  includeCSS: true,
  showSpinner: true,
})

import NProgress from 'nprogress'
NProgress.configure({ parent: '#app-container' })

import { app, plugin } from '@inertiajs/inertia-vue'
Vue.use(plugin)

import Routes from '@/utils/routes.js'
Vue.prototype.$routes = Routes
import AdminRoutes from '@/utils/admin_routes.js'
Vue.prototype.$adminRoutes = AdminRoutes

import '@/utils/filters.js'

const el = document.getElementById('app')

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - CarefreeIO` : 'CarefreeIO',
  },
  render: h => h(app, {
    props: {
      initialPage: JSON.parse(el.dataset.page),
      resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
    },
  }),
}).$mount(el)
