/*
File generated by js-routes 1.4.13
Based on Rails 6.0.3.4 routes of Cfio::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_account => /admin/accounts/:id(.:format)
  // function(id, options)
  admin_account: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_accounts => /admin/accounts(.:format)
  // function(options)
  admin_accounts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_api_notification_groups => /admin/api/notification_groups(.:format)
  // function(options)
  admin_api_notification_groups: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"notification_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_api_voice_groups => /admin/api/voice_groups(.:format)
  // function(options)
  admin_api_voice_groups: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"voice_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_device => /admin/devices/:id(.:format)
  // function(id, options)
  admin_device: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"devices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_devices => /admin/devices(.:format)
  // function(options)
  admin_devices: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"devices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_root => /admin(.:format)
  // function(options)
  admin_root: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer => /blazer
  // function(options)
  blazer: Utils.route([], {}, [2,[7,"/",false],[6,"blazer",false]]),
// blazer.run_queries => /blazer/queries/run(.:format)
  // function(options)
  blazer_run_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.cancel_queries => /blazer/queries/cancel(.:format)
  // function(options)
  blazer_cancel_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.refresh_query => /blazer/queries/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.tables_queries => /blazer/queries/tables(.:format)
  // function(options)
  blazer_tables_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.schema_queries => /blazer/queries/schema(.:format)
  // function(options)
  blazer_schema_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.docs_queries => /blazer/queries/docs(.:format)
  // function(options)
  blazer_docs_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.queries => /blazer/queries(.:format)
  // function(options)
  blazer_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_query => /blazer/queries/new(.:format)
  // function(options)
  blazer_new_query: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_query => /blazer/queries/:id/edit(.:format)
  // function(id, options)
  blazer_edit_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.query => /blazer/queries/:id(.:format)
  // function(id, options)
  blazer_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.run_check => /blazer/checks/:id/run(.:format)
  // function(id, options)
  blazer_run_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.checks => /blazer/checks(.:format)
  // function(options)
  blazer_checks: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_check => /blazer/checks/new(.:format)
  // function(options)
  blazer_new_check: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_check => /blazer/checks/:id/edit(.:format)
  // function(id, options)
  blazer_edit_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.check => /blazer/checks/:id(.:format)
  // function(id, options)
  blazer_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.refresh_dashboard => /blazer/dashboards/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.dashboards => /blazer/dashboards(.:format)
  // function(options)
  blazer_dashboards: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_dashboard => /blazer/dashboards/new(.:format)
  // function(options)
  blazer_new_dashboard: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_dashboard => /blazer/dashboards/:id/edit(.:format)
  // function(id, options)
  blazer_edit_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.dashboard => /blazer/dashboards/:id(.:format)
  // function(id, options)
  blazer_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.root => /blazer/
  // function(options)
  blazer_root: Utils.route([], {}, [2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]]),
// edit_admin_account => /admin/accounts/:id/edit(.:format)
  // function(id, options)
  edit_admin_account: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_device => /admin/devices/:id/edit(.:format)
  // function(id, options)
  edit_admin_device: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"devices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_admin_account => /admin/accounts/new(.:format)
  // function(options)
  new_admin_account: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_device => /admin/devices/new(.:format)
  // function(options)
  new_admin_device: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"devices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.accounts_admin_index => /trestle/accounts(.:format)
  // function(options)
  trestle_accounts_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_accounts_admin => /trestle/accounts/new(.:format)
  // function(options)
  trestle_new_accounts_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_accounts_admin => /trestle/accounts/:id/edit(.:format)
  // function(id, options)
  trestle_edit_accounts_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.accounts_admin => /trestle/accounts/:id(.:format)
  // function(id, options)
  trestle_accounts_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.contacts_admin_index => /trestle/contacts(.:format)
  // function(options)
  trestle_contacts_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_contacts_admin => /trestle/contacts/new(.:format)
  // function(options)
  trestle_new_contacts_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_contacts_admin => /trestle/contacts/:id/edit(.:format)
  // function(id, options)
  trestle_edit_contacts_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.contacts_admin => /trestle/contacts/:id(.:format)
  // function(id, options)
  trestle_contacts_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.device_signal_events_admin_index => /trestle/device_signal_events(.:format)
  // function(options)
  trestle_device_signal_events_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signal_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_device_signal_events_admin => /trestle/device_signal_events/new(.:format)
  // function(options)
  trestle_new_device_signal_events_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signal_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_device_signal_events_admin => /trestle/device_signal_events/:id/edit(.:format)
  // function(id, options)
  trestle_edit_device_signal_events_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signal_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.device_signal_events_admin => /trestle/device_signal_events/:id(.:format)
  // function(id, options)
  trestle_device_signal_events_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signal_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.device_signals_admin_index => /trestle/device_signals(.:format)
  // function(options)
  trestle_device_signals_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_device_signals_admin => /trestle/device_signals/new(.:format)
  // function(options)
  trestle_new_device_signals_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signals",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_device_signals_admin => /trestle/device_signals/:id/edit(.:format)
  // function(id, options)
  trestle_edit_device_signals_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.device_signals_admin => /trestle/device_signals/:id(.:format)
  // function(id, options)
  trestle_device_signals_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"device_signals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.devices_admin_index => /trestle/devices(.:format)
  // function(options)
  trestle_devices_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"devices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_devices_admin => /trestle/devices/new(.:format)
  // function(options)
  trestle_new_devices_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"devices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_devices_admin => /trestle/devices/:id/edit(.:format)
  // function(id, options)
  trestle_edit_devices_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"devices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.devices_admin => /trestle/devices/:id(.:format)
  // function(id, options)
  trestle_devices_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"devices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.notification_groups_admin_index => /trestle/notification_groups(.:format)
  // function(options)
  trestle_notification_groups_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"notification_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_notification_groups_admin => /trestle/notification_groups/new(.:format)
  // function(options)
  trestle_new_notification_groups_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"notification_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_notification_groups_admin => /trestle/notification_groups/:id/edit(.:format)
  // function(id, options)
  trestle_edit_notification_groups_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"notification_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.notification_groups_admin => /trestle/notification_groups/:id(.:format)
  // function(id, options)
  trestle_notification_groups_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"notification_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.raw_events_admin_index => /trestle/raw_events(.:format)
  // function(options)
  trestle_raw_events_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"raw_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_raw_events_admin => /trestle/raw_events/new(.:format)
  // function(options)
  trestle_new_raw_events_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"raw_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_raw_events_admin => /trestle/raw_events/:id/edit(.:format)
  // function(id, options)
  trestle_edit_raw_events_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"raw_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.raw_events_admin => /trestle/raw_events/:id(.:format)
  // function(id, options)
  trestle_raw_events_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"raw_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.sms_logs_admin_index => /trestle/sms_logs(.:format)
  // function(options)
  trestle_sms_logs_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"sms_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_sms_logs_admin => /trestle/sms_logs/new(.:format)
  // function(options)
  trestle_new_sms_logs_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"sms_logs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_sms_logs_admin => /trestle/sms_logs/:id/edit(.:format)
  // function(id, options)
  trestle_edit_sms_logs_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"sms_logs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.sms_logs_admin => /trestle/sms_logs/:id(.:format)
  // function(id, options)
  trestle_sms_logs_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"sms_logs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.users_admin_index => /trestle/users(.:format)
  // function(options)
  trestle_users_admin_index: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trestle.new_users_admin => /trestle/users/new(.:format)
  // function(options)
  trestle_new_users_admin: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trestle.edit_users_admin => /trestle/users/:id/edit(.:format)
  // function(id, options)
  trestle_edit_users_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trestle.users_admin => /trestle/users/:id(.:format)
  // function(id, options)
  trestle_users_admin: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"trestle",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    module.exports = result;
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
